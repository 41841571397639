module.exports = {
  "size": 5,
  "cellSize": 10,
  "extrudeSettings": {
    "amount": 1,
    "bevelEnabled": true,
    "bevelSegments": 1,
    "steps": 1,
    "bevelSize": 0.5,
    "bevelThickness": 0.5
  },
  "autogenerated": true,
  "cells": [
    {
      "q": -1,
      "r": 0,
      "s": 1,
      "h": 1,
      "walkable": true,
      "userData": {}
    },
    {
      "q": 0,
      "r": -1,
      "s": 1,
      "h": 1,
      "walkable": true,
      "userData": {}
    },
    {
      "q": 0,
      "r": 0,
      "s": 0,
      "h": 1,
      "walkable": true,
      "userData": {}
    },
    {
      "q": 1,
      "r": -1,
      "s": 0,
      "h": 1,
      "walkable": true,
      "userData": {}
    },
    {
      "q": -1,
      "r": 1,
      "s": 0,
      "h": 0,
      "walkable": true,
      "userData": {}
    },
    {
      "q": 0,
      "r": 1,
      "s": -1,
      "h": 0,
      "walkable": true,
      "userData": {}
    },
    {
      "q": 1,
      "r": 0,
      "s": -1,
      "h": 0,
      "walkable": true,
      "userData": {}
    }]
};
