// Created by Matt Webb
// holds all the components for the homepage
// if renabling twitter and github login go back to old commits and add back in here
import PrimaryFeatures2 from "./primaryFeatures2.js";
import SecondaryFeatures from "./secondaryFeatures.js";
import HeroSection from "./heroSection.js";
import BlockchainsContainer from "./blockchainsContainer.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
function Homepage() {
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (location.state?.pathname) {
      navigate("/", { replace: true });
      window.location.reload();
    }
  }, [location.state?.pathname]);

  return (
    <div className="pb-8">
      <HeroSection />
      <PrimaryFeatures2 />
      <BlockchainsContainer />
      <SecondaryFeatures />
    </div>
  );
}

export default Homepage;
