import React, { useState, useEffect } from "react";
import { DataTable } from "../Ui/data-table";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../Ui/hover-card";
import { useTableData } from "./API/GetTableData";
import HorizontalBar from "./HorizontalBar";
import DescriptionSection from "./DescriptionSection";
import { Card, CardContent } from "../Ui/card";
import TitleSection from "./TitleSection";
import TableSkeleton from "./TableSkeleton";
import LoginPrompt from "./LoginPrompt";

const TableView = ({
  blockchain,
  selectedTable,
  onGranularityChange,
  onFilterChange,
  filters,
  isLoggedIn,
  isGrid = false,
}) => {
  const [interval, setInterval] = useState(selectedTable?.interval || "days");

  useEffect(() => {
    setInterval(selectedTable?.interval || "days");
  }, [selectedTable]);

  const { data, isLoading, isError, error } = useTableData(
    blockchain,
    {
      name: selectedTable?.name,
      interval,
    },
    selectedTable?.compare,
    filters
  );

  // const capitalizeFirstLetter = (str) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // };

  const handleFilterChange = (selectedFilters) => {
    onFilterChange(selectedFilters);
  };

  const handleGranularityChange = (granularity) => {
    setInterval(granularity);
    if (typeof onGranularityChange === "function") {
      onGranularityChange(granularity);
    }
  };

  const filteredData = isGrid
    ? data?.graphData?.map(
        ({
          timestamp,
          token_holding,
          avg_buy_price,
          avg_buy_price_weth,
          ...rest
        }) => rest
      )
    : data?.graphData;

  if (isError) {
    if (error.response?.status != 401) {
      return <div>Error loading table data: {error.message}</div>;
    }
  }
  const lastEdited = data?.lastEdited;

  return (
    <div className={`flex flex-col pt-2 ${isGrid ? "h-[1000px]" : ""}`}>
      <CardContent className={`flex flex-col ${isGrid ? "h-full" : ""}`}>
        <TitleSection
          name={selectedTable?.name}
          description={selectedTable?.description}
          lastEdited={lastEdited}
          isGrid={isGrid}
          blockchain={blockchain}
          interval={interval}
        />
        <HorizontalBar
          selectedTable={selectedTable}
          onFilterChange={handleFilterChange}
          onGranularityChange={handleGranularityChange}
          queryData={data}
          isGrid={isGrid}
        />
        <div
          className={`relative ${isGrid ? "flex-grow overflow-hidden" : ""}`}
        >
          {isLoading ? (
            <TableSkeleton
              columns={selectedTable?.columns}
              rows={isGrid ? 10 : 15}
              isGrid={isGrid}
            />
          ) : filteredData ? (
            <div className={isGrid ? "h-full overflow-auto" : ""}>
              <DataTableWithRankComparison
                data={filteredData}
                comparisonData={data?.comparisonData}
                pageSize={isGrid ? 10 : 15}
              />
            </div>
          ) : (
            <TableSkeleton
              columns={selectedTable?.columns}
              rows={15}
              isGrid={isGrid}
            />
          )}
          {!isLoggedIn && (
            <>
              <div className="absolute inset-0 bg-white opacity-75 blur-sm"></div>
              <LoginPrompt />
            </>
          )}
        </div>
        <div className={`mt-2 ${isGrid ? "text-sm" : ""}`}>
          <DescriptionSection
            description={selectedTable?.description || ""}
            sources={selectedTable?.sources || []}
            dataSource={selectedTable?.dataSource || ""}
            category={selectedTable?.category || ""}
          />
        </div>
      </CardContent>
    </div>
  );
};

const RankOverlay = ({ rank, rankChange }) => {
  const isNewEntry = rankChange === null || isNaN(rankChange);
  const changeColor =
    rankChange > 0
      ? "text-green-500"
      : rankChange < 0
      ? "text-red-500"
      : "text-gray-500";
  const arrow = rankChange > 0 ? "↑" : rankChange < 0 ? "↓" : "";

  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="flex flex-col items-center 2xl:ml-14 ml-16">
        {isNewEntry ? (
          <span className="text-xs text-primary">New!</span>
        ) : (
          <div className="flex items-center">
            {rankChange !== 0 ? (
              <>
                <span className={`mr-1 text-lg ${changeColor}`}>{arrow}</span>
                <span className={`text-xs md:text-sm ${changeColor}`}>
                  {Math.abs(rankChange)}
                </span>
              </>
            ) : (
              <span className="text-sm text-gray-500">-</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const DataTableWithRankComparison = ({ data, comparisonData, pageSize }) => {
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const generateColumnsWithRankComparison = (data, comparisonData) => {
    if (!data || data.length === 0) {
      return [];
    }

    const columns = Object.keys(data[0]).map((key) => ({
      accessorKey: key,
      header: key.split("_").map(capitalizeFirstLetter).join(" "),
      width: key.toLowerCase().includes("rank") ? 100 : undefined,
      cell: (info) => {
        const value = info.getValue();
        const holder = info.row.original.holder_address;

        const comparisonItem = comparisonData
          ? comparisonData.find((item) => item.holder === holder)
          : null;

        const rankChange = comparisonItem
          ? comparisonItem["rank_change"]
          : null;

        const addressKeys = [
          "token_address",
          "holder_address",
          "addr",
          "address",
          "Holder",
          "Holder Address",
        ];
        if (
          addressKeys.includes(key.toLowerCase()) &&
          typeof value === "string"
        ) {
          return (
            <HoverCard>
              <HoverCardTrigger>
                <span>{`${value.slice(0, 5)}...${value.slice(-5)}`}</span>
              </HoverCardTrigger>
              <HoverCardContent className="bg-white p-2 rounded-md shadow-lg border border-gray-200 w-auto">
                <span className="block">{value}</span>
              </HoverCardContent>
            </HoverCard>
          );
        }

        if (key.toLowerCase().includes("timestamp")) {
          return <span className="text-sm text-gray-900">{value}</span>;
        }

        const changeValue = comparisonItem
          ? comparisonItem[`${key}_change`]
          : null;
        const formattedChangeValue =
          changeValue !== undefined &&
          changeValue !== null &&
          typeof changeValue === "number"
            ? `${Math.abs(changeValue).toFixed(2)}%`
            : "";

        let changeColor = "text-gray-500";
        let textColor = "text-gray-900";

        if (
          changeValue !== undefined &&
          changeValue !== null &&
          typeof changeValue === "number"
        ) {
          if (changeValue > 0) {
            changeColor = "text-green-500";
            textColor = "text-green-500";
          } else if (changeValue < 0) {
            changeColor = "text-red-500";
            textColor = "text-red-500";
          }
        }

        if (key.toLowerCase().includes("rank")) {
          return (
            <div className="relative">
              <span className="text-lg">{value}</span>
              <RankOverlay rank={value} rankChange={rankChange} />
            </div>
          );
        }

        return (
          <div className="flex flex-col items-start">
            <span className={`text-sm ${textColor}`}>{value}</span>
            {formattedChangeValue && (
              <span className={`text-xs ${changeColor}`}>
                {formattedChangeValue}
              </span>
            )}
          </div>
        );
      },
    }));

    const rankColumnIndex = columns.findIndex((col) =>
      col.accessorKey.toLowerCase().includes("rank")
    );
    if (rankColumnIndex !== -1) {
      const rankColumn = columns.splice(rankColumnIndex, 1)[0];
      columns.unshift(rankColumn);
    }

    return columns;
  };

  const columns = generateColumnsWithRankComparison(data, comparisonData);

  return (
    <DataTable
      data={data}
      columns={columns}
      pageSize={pageSize}
      roundToDecimal={4}
      className="table-auto w-full"
      style={{
        width: "100%",
        tableLayout: "auto",
        whiteSpace: "nowrap",
      }}
    />
  );
};

export default TableView;
