// created by Matt Webb
// contains first part of homepage with background, header and data.
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import TwistComponentGrayLight from "./TwistComponentGrayLigh";
import { useQueries } from "@tanstack/react-query";
import request from "../../Api/Api.js";
const names = [
  "Our Way",
  "Your Way",
  "Vitaliks Way",
  "Ethereums Way",
  "Weis Way",
];

function HeroSection() {
  const [newName, setnewName] = useState("Your Way");
  const navigate = useNavigate();
  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * names.length);
    setnewName(names[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  const [blocks, size, chains] = useQueries({
    queries: [
      {
        queryKey: ["get-blocks"],
        queryFn: () => request.get("/homepageBlocks"),
        staleTime: Infinity,
      },
      {
        queryKey: ["get-size"],
        queryFn: () => request.get("/homepageSize"),
        staleTime: Infinity,
      },
      {
        queryKey: ["get-chains"],
        queryFn: () => request.get("/homepageChains"),
        staleTime: Infinity,
      },
    ],
  });

  return (
    <div className="relative pt-10 pb-20 sm:py-24 z-0 overflow-hidden">
      <div>
        <div className="relative  mx-auto xl:max-w-[90rem]">
          <TwistComponentGrayLight className="absolute -z-30 rotate-[30deg] top-[-400px] md:top-[-350px] lg:top-[-350px] xl:top-[-350px] 2xl:top-[-350px] left-[-250px] md:left-[-460px] lg:left-[-380px] xl:left-[-400px] 2xl:left-[-500px] h-[1200px] w-[1000px] md:h-[1100px] md:w-[900px] lg:h-[1000px] lg:w-[1000px] xl:h-[1200px] xl:w-[1200px] 2xl:h-[1300px] 2xl:w-[1300px] blur-sm md:block hidden" />
          <TwistComponentGrayLight className="absolute -z-30 rotate-[20deg] sm:rotate-[30deg] lg:rotate-[20deg] top-[-130px] sm:top-[-200px] md:top-[-200px] lg:top-[-250px] xl:top-[-400px] 2xl:top-[-450px] left-[0px] sm:left-[150px] md:left-[300px] lg:left-[500px] xl:left-[550px] 2xl:left-[700px] h-[800px] w-[800px] sm:h-[1000px] sm:w-[1000px] md:h-[1000px] md:w-[1000px] lg:h-[1000px] lg:w-[1000px] xl:h-[1200px] xl:w-[1200px] 2xl:h-[1300px] 2xl:w-[1100px] blur-sm block transform -scale-100" />
        </div>
        <div className="absolute inset-x-0 bottom-0 left-0  h-60 bg-gradient-to-t from-primaryfeatures2 -z-10" />
      </div>
      {/* here is where we deal with the crampness inside the container lg:px-12*/}

      <div className="relative mx-auto max-w-[90rem] pb-4">
        <div className="flex flex-col items-center text-center font-roboto ">
          <h1 className=" text-[48px] font-bold tracking-tighter text-primary sm:text-[78px] xl:text-[90px] font-monda">
            <span className=" text-primary font-extrabold hidden sm:block">
              Data {newName}
            </span>
            <span className=" text-primary font-extrabold block sm:hidden">
              Data your way
            </span>
          </h1>
          <div className="mt-6 tracking-tight font-roboto font-light sm:px-0 px-4">
            <p className=" text-xl md:text-2xl text-gray-500">
              Try the new way to access fast and reliable blockchain data in an
              instant.
            </p>
            <p className="text-xl md:text-2xl text-gray-500 sm:max-w-2xl">
              At Wevr you will learn about the latest data trends with our suite
              of charts, news sentiment and soon to come footprint tracker
            </p>
          </div>
          <div className="flex items-center gap-x-6 mt-10">
            <button
              onClick={() => {
                navigate("/Explore?blockchain=Ethereum");
              }}
              className="bg-primary text-md px-4 py-3 sm:px-5 sm:py-2.5 sm:text-lg rounded-lg text-center font-semibold shadow-md  hover:shadow-lg text-white cursor-pointer"
            >
              Get started
            </button>
            <button
              onClick={() => {
                navigate("/Pricing");
              }}
              className="text-gray-500 text-md sm:text-lg py-4 px-3 w-40 text-center font-semibold  flex items-center gap-1 "
            >
              Learn more
              <ArrowSmallRightIcon className="mt-[1px] sm:mt-0 h-[16px] w-[16px] sm:h-[18px] sm:w-[18px]" />
            </button>
          </div>

          <div className="mt-16 justify-between grid grid-cols-2 gap-y-6 gap-x-10 sm:mt-16 sm:gap-y-10 sm:gap-x-16 sm:text-center lg:auto-cols-auto lg:grid-flow-col lg:grid-cols-none lg:text-left font-monda">
            {[
              ["Blocks created", blocks, ""],
              ["Database Size", size, "GB"],
              ["Blockchains", chains, ""],
              // ["Speed", "Fast"],
            ].map(([name, value, text]) => (
              <div key={name}>
                <div className="font-monda text-lg text-primary">{name}</div>
                <div className="mt-0.5 text-xl font-semibold tracking-tight text-secondary">
                  {value.isLoading ? (
                    "Loading..."
                  ) : (
                    <div>
                      {value.isError ? (
                        "error"
                      ) : (
                        <div>
                          {value.data.data.count.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}{" "}
                          {text}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div>
              <div className="font-monda text-lg text-primary">Speed</div>
              <div className="mt-0.5 text-xl font-semibold tracking-tight text-secondary">
                Fast
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeroSection;
