export {
  createCheckboxScope,
  //
  Checkbox,
  CheckboxIndicator,
  //
  Root,
  Indicator,
} from './Checkbox';
export type { CheckboxProps, CheckboxIndicatorProps } from './Checkbox';
