import React, { useEffect, useRef, useState } from "react";
import { ForceGraph2D } from "react-force-graph";
import ethIcon from "../../../Assets/eth.svg"; // Adjust the path as necessary

// Updated calculateCurvature function
const calculateCurvature = (link, index, links) => {
  // Group links by the combination of source and target to handle parallel links
  const sameLinks = links.filter(
    (l) => l.source.id === link.source.id && l.target.id === link.target.id
  );
  const idx = sameLinks.indexOf(link);
  const gap = 0.025; // Adjust gap size as needed
  // This will create a symmetric pattern for the links
  const curvature = (idx - (sameLinks.length - 1) / 2) * gap;
  return curvature;
};
const NetworkGraph = ({ data }) => {
  const widthScale = 0.85;
  const heightScale = 0.9;

  // State for window dimensions
  const [displayWidth, setDisplayWidth] = useState(
    window.innerWidth * widthScale
  );
  const [displayHeight, setDisplayHeight] = useState(
    window.innerHeight * heightScale
  );

  // Event listener to update size on window resize
  useEffect(() => {
    const handleResize = () => {
      setDisplayWidth(window.innerWidth * widthScale);
      setDisplayHeight(window.innerHeight * heightScale);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const fgRef = useRef();

  const defaultImage = useRef(new Image());
  useEffect(() => {
    defaultImage.current.src = ethIcon;
  }, []);

  useEffect(() => {
    data.nodes.forEach((node) => {
      if (node.image) {
        const img = new Image();
        img.src = `data:image/png;base64,${node.image}`;
        img.onload = () => {
          node.img = img;
          // Optionally refresh the graph to display the newly loaded image
          //fgRef.current.refresh();
        };
        img.onerror = () => {
          console.error("Image failed to load, using default:", node.image);
          // Use the preloaded default ETH icon
          node.img = defaultImage.current;
        };
      } else {
        // No image URL, use the default ETH icon
        node.img = defaultImage.current;
      }
    });
  }, [data.nodes]);
  return (
    <ForceGraph2D
      ref={fgRef}
      graphData={data}
      width={displayWidth}
      linkLabel={(link) => `Value: ${link.value}`} // Add this line
      height={displayHeight}
      linkCurvature={(link, index) =>
        calculateCurvature(link, index, data.links)
      }
      nodeCanvasObject={(node, ctx, globalScale) => {
        const size = 25 / globalScale; // Adjust size relative to zoom level
        const x = node.x - size / 2;
        const y = node.y - size / 2;

        if (node.img) {
          ctx.drawImage(node.img, x, y, size, size);
        } else {
          ctx.beginPath();
          ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI, false);
          ctx.fillStyle = node.color || "red";
          ctx.fill();
        }
      }}
      nodeLabel={(node) => {
        return `Address: ${node.id}`; // Check if node.id is defined
      }}
      nodeAutoColorBy="group"
      cooldownTicks={50}
      onEngineStop={() => fgRef.current.zoomToFit(200)}
      linkColor={(link) => link.color}
    />
  );
};

export default NetworkGraph;
