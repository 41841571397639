// Created by Matt Webb
// Vert nav bar with photos functionality is here

import { useState } from "react";
import { useNavigate } from "react-router";
import Alpha from "../../Assets/Alpha.svg";
import Risks from "../../Assets/Risks.svg";
import Trade from "../../Assets/Trade.svg";
import Research from "../../Assets/Research.svg";
const features = [
  {
    title: "Find Alpha",
    description:
      "Covering on-chain to whale tracking to miner extraceted values",
    image: Alpha,
  },
  {
    title: "Research the Bleeding Edge",
    description:
      "Wevr offers multiple layer-1 blockchains that are constantly updated at block time",
    image: Research,
  },
  {
    title: "Learn the Risks",
    description:
      "Users can research new opportuntities using our data to potentially decrease their risk",
    image: Risks,
  },
  {
    title: "Trade like the Best",
    description:
      "Wevr offers an api to our premium users providing instantaneous data",
    image: Trade,
  },
];

function primaryFeatures2() {
  const [activeImage, setActiveImage] = useState(features[0].image);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  return (
    <section className="relative overflow-hidden pb-28">
      <div className=" flex flex-col items-center px-4 bg-primaryfeatures2">
        <div className="text-center max-w-[90rem] w-full ">
          <h2 className="text-4xl sm:text-5xl md:text-5xl text-gray-800 tracking-tight font-monda font-extrabold justify-center">
            Capitalize on the wealth of Data
          </h2>
          <p className="mt-4 sm:mt-6 text-lg sm:text-1xl md:text-2xl text-gray-600 tracking-tight font-monda font-bold pb-2">
            Create. Visualize. Learn and empower yourself with Wevr
          </p>
        </div>
      </div>
      {/* For screen size larger than large */}
      <div className="relative flex flex-col items-center pt-8">
        <div className="absolute inset-x-0 top-0 left-0  h-40 bg-gradient-to-b from-primaryfeatures2 -z-10" />
        <div className="mt-2 max-w-[90rem] md:mt-16 font-roboto hidden md:block px-4">
          {/* create cols for tab group and image */}
          <div className="grid items-center grid-cols-16 ">
            {/* buttons */}

            <div className="flex flex-col md:col-span-5 lg:col-span-4 gap-2">
              {features.map((feature, index) => (
                <button
                  key={feature.title}
                  onFocus={() => {
                    setActiveImage(feature.image);
                    setActiveTab(index);
                  }}
                  onMouseOver={() => {
                    setActiveImage(feature.image);
                    setActiveTab(index);
                  }}
                  className={`${
                    activeTab == index ? "bg-primary" : "bg-surfaceVariant "
                  } group py-1 px-4 rounded-l-lg md:p-2 lg:p-6 cursor-default border border-primaryContainer shadow-md`}
                >
                  <div
                    className={`${
                      activeTab == index ? "text-white" : ""
                    } text-xs sm:text-lg lg:text-xl font-bold rounded-md`}
                  >
                    {feature.title}
                  </div>
                  <div
                    className={`${
                      activeTab == index ? "text-gray-50" : "text-gray-800"
                    }  mt-2 sm:text-xs lg:text-sm  `}
                  >
                    {feature.description}
                  </div>
                </button>
              ))}
              <button
                onClick={() => navigate("/Pricing")}
                className="group py-1 px-4 rounded-l-lg lg:p-6 bg-surfaceVariant border border-primaryContainer shadow-md hover:bg-primary hover:text-white"
              >
                <div className="text-xs sm:text-lg lg:text-xl font-bold rounded-md ">
                  Click to see more!
                </div>
              </button>
            </div>

            {/* picture */}
            <div className="flex align-center md:col-span-11 lg:col-span-12 md:h-[450px] lg:h-[770px] overflow-hidden rounded-xl shadow-xl shadow-blue-900/20  lg:mt-0">
              <img
                className="object-fit z-10 rounded-md transition-all"
                src={activeImage}
                alt=""
              />
            </div>
          </div>
        </div>
        {/* screen size smaller than lg */}
        <div className="mt-2 flex flex-col font-roboto md:hidden gap-4 h-fit pt-8 px-4">
          {features.map((feature) => (
            <div
              key={feature.title}
              className="p-4 border border-primaryContainer shadow-md rounded-lg"
            >
              <img
                className="object-fit rounded-lg h-48 w-fit"
                src={feature.image}
                alt=""
              />
              <div className="text-xl font-bold text-center mt-3">
                {feature.title}
              </div>
              <div className=" mt-1 text-center text-sm text-gray-600 pb-2">
                {feature.description}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 left-0 h-60 bg-gradient-to-t from-primaryfeatures2 -z-10" />
    </section>
  );
}
export default primaryFeatures2;
