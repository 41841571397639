// created by Matt Webb
// holds to supported blockchains for the homepage
import eth from "../../Assets/eth.svg";
import btc from "../../Assets/btc.svg";
import solana from "../../Assets/solana.svg";

function blockchainsContainer() {
  return (
    <section className="relative overflow-hidden pt-8 pb-40 px-4 sm:py-6 bg-primaryfeatures2">
      <div className="grid grid-rows-2 gap-6">
        <h2 className="mx-auto max-w-md text-center text-3xl font-bold tracking-tight text-steel lg:max-w-xl lg:text-left font-monda mt-1">
          Support for the most popular blockchains
        </h2>
        <div className="flex flex-auto grow justify-center">
          <div className="max-w-md flex grow items-center justify-between ">
            <div className="justify-center">
              <img className="h-12" src={eth} alt="Workcation" />
            </div>
            <div className="justify-center">
              <img className="h-12" src={btc} alt="Workcation" />
            </div>
            <div className="justify-center">
              <img className="h-12" src={solana} alt="Workcation" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default blockchainsContainer;
