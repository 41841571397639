// Created by James Launder
// currently bottom part of the homepage with clickable redirect to key pages
import {
  ChartBarIcon,
  FingerPrintIcon,
  RectangleGroupIcon,
  RssIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";

function secondaryFeatures() {
  const data = [
    {
      icon: <ChartBarIcon className="h-12 w-12 text-white" />,
      header: "Explore",
      body: "Gain unmatched insight by investigating Wevr's 100+ charts on each supported blockchain",
      link: "/Explore?blockchain=Ethereum",
    },
    {
      icon: <RssIcon className="h-12 w-12 text-white" />,
      header: "Discover",
      body: "See compiled news and sort by relevant categories as well as see sentiment for each article",
      link: "/Discover",
    },
    {
      icon: <RectangleGroupIcon className="h-12 w-12 text-white" />,
      header: "Dashboard",
      body: "Create your own custom dashboard to see data your way. Compare cross chain data and set alerts",
      link: "/Dashboard",
    },
    {
      icon: <FingerPrintIcon className="h-12 w-12 text-white" />,
      header: "Footprint",
      body: "Wevr tracks the biggest wallet addresses to keep you updated with inflows and outflows.",
      link: "/Footprint",
    },
  ];
  const navigate = useNavigate();
  return (
    <div className="relative overflow-hidden">
      <div className="absolute inset-x-0 top-0 left-0 h-60 bg-gradient-to-b from-primaryfeatures2 -z-10" />
      <div className="flex flex-col items-center">
        <div className="relative pt-6 pb-10 sm:pt-15 lg:pt-20 px-4 ">
          <div className="mx-auto  text-center  ">
            {/* <h2 className="text-md sm:text-lg font-semibold uppercase tracking-wider text-gray-900 font-monda">
            Get Data Faster
          </h2> */}
            <p className="mt-2 text-2xl font-semibold tracking-tight text-primary sm:text-4xl font-roboto">
              Everything you need to make the best decisions possible
            </p>
            <p className="mx-auto mt-5 max-w-prose text-lg sm:text-xl text-gray-700 font-roboto font-light">
              Wevr offers a vast variety of tools such as graphing of all the
              major blockchains, alongside news with sentiment data
            </p>
          </div>
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-2 gap-10  p-4 font-roboto place-content-center md:w-[800px] lg:w-[1000px] xl:w-[1280px]">
          {/* <div className=" bg-green-500 lg:mx-auto grid lg:grid-cols-4 lg:gap-x-8 sm:grid sm:grid-cols-2 sm:gap-6 xl:max-w-none xl:mx-0 xl:grid-cols-4 sm:gap-y-4 sm:px-2 lg:px-8 py-8"> */}
          {data.map((data) => (
            <button
              key={data.header}
              onClick={() => {
                navigate(data.link);
              }}
              className="flex flex-col rounded-lg p-2 py-4 md:p-4 md:py-10 text-center items-center border border-primaryContainer shadow-md sm:hover:-translate-y-1 sm:hover:scale-110 transition ease-in-out drop-shadow-lg bg-surfaceVariant"
            >
              <div className="mt-4 mx-auto bg-primary rounded-md py-2 px-2">
                {data.icon}
              </div>
              <div className="text-2xl text-gray-900 mt-6">{data.header}</div>
              <div className="text-md text-gray-600 mt-4 px-[3rem] mb-4 ">
                {data.body}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
export default secondaryFeatures;
