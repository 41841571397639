// Created by James Launder
// Contains all the functions and the chart to display the dataGrouping
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/boost")(Highcharts);
require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/ema")(Highcharts);
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
// import watermarkIcon from "../../Assets/Wevr-ai-4-V2-Secondary.svg";
import watermarkIcon from "../../Assets/WevrWatermark.svg";
import watermarkIconSmall from "../../Assets/WevrLogoSecondary.svg";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import useChartsQuery from "./useChartsQuery";

function Chart(props) {
  const [searchParams] = useSearchParams();
  const chartRef = useRef(null);
  const { results } = useChartsQuery();
  // const setSidebar = useSidebarState((state) => state.setSidebar);
  // console.log(results);
  const emaRef = useRef();
  const smaRef = useRef();
  //   const { theme } = useContext(ThemeContext);
  const [text] = useState();
  const { width } = useWindowDimensions();

  var watermark = "";
  var largeWatermark = watermarkIcon;
  var smallWatermark = watermarkIconSmall;
  // this allow us to set a custom font for the chart
  var newStyle = document.createElement("style");
  newStyle.appendChild(
    document.createTextNode(
      "\
@font-face {\
  font-family: 'Monda';\
  font-style: sans-serif;\
  font-weight: 400;\
  src: url('https://fonts.googleapis.com/css2?family=Monda&display=swap')\
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\
}\
"
    )
  );

  document.head.appendChild(newStyle);

  // Used to add new data to the chart for the compare function.
  const createData = (
    name,
    data,
    color,
    scale,
    style,
    blockchain,
    source,
    resolution
  ) => {
    // creates a temporary new data object that contains tha data and ID
    // the grouping may be adjusted in the future as use may not be required
    var newData = {
      id: name + blockchain + source + resolution,
      name: name,
      data: data,
      yAxis: "yaxis" + name + blockchain + source + resolution,
      color: color,
      type: style,
      // dataGrouping: {
      //   approximation: "average",
      //   enabled: false,
      //   forced: true,
      //   units: [["day", [1]]],
      // },
    };
    dataSource.push(newData);
    // creates a new Y axis to go along with the new chart
    var newYaxis = {
      id: "yaxis" + name + blockchain + source + resolution,
      opposite: !yAxisSetup[yAxisSetup.length - 1].opposite,
      type: scale,
      labels: {
        style: {
          fontSize: "14px",
          color: color,
        },
      },
      events: {},
    };
    yAxisSetup.push(newYaxis);

    // this updates the chartOptions with the new data
    setChartOptions({
      series: dataSource,
      yAxis: yAxisSetup,
    });
  };

  // used for removing an additional chart
  const removeData = (name, blockchain, source, resolution) => {
    // resets the state used to pass through updates and removals
    props.setUpdateChart(null);
    props.setRemove(null);
    // find the chart based on the ID created
    const objWithIdIndex = dataSource.findIndex(
      (obj) => obj.id === name + blockchain + source + resolution
    );
    const yAxisIndex = yAxisSetup.findIndex(
      (obj) => obj.id === dataSource[objWithIdIndex].yAxis
    );
    yAxisSetup.splice(yAxisIndex, 1);

    dataSource.splice(objWithIdIndex, 1);
    setChartOptions({
      series: dataSource,
      yAxis: yAxisSetup,
    });
  };

  // used to keep the charts in sync with the query data
  const checkForNewChart = () => {
    // this gets all the data that has an id in the json
    // used due to SMA and EMA lines getting added and causing wrong count
    let amntOfCharts = chartOptions.series.filter((x) => x["id"]).length;
    if (props.dataV2.length > amntOfCharts) {
      if (!props.dataV2.some((results) => results.isFetching)) {
        if (!props.dataV2.some((results) => results.isError)) {
          // reduce the current series down to just the name values
          let newChart = Object.keys(
            chartOptions.series.reduce(
              (a, { id }) => Object.assign(a, { [id]: undefined }),
              {}
            )
          );
          // check the additional charts array to find the chart that is neww
          let filtered = props.additionalCharts.filter(
            ({ id }) => !newChart.includes(id)
          );
          var index = -1;
          if (filtered.length > 0) {
            // finds the index of each new chart
            filtered.forEach((data) => {
              if (data?.source === null) {
                index = props.dataV2.findIndex(function (el) {
                  return (
                    el.data.config.url ===
                    "/" +
                      data.blockchain +
                      "/" +
                      data.resolution +
                      "/" +
                      data.name
                  );
                });
              } else {
                index = props.dataV2.findIndex(function (el) {
                  return (
                    el.data.config.url ===
                    "/" +
                      data.blockchain +
                      "/" +
                      data.resolution +
                      "/" +
                      data.name +
                      "/" +
                      data.source
                  );
                });
              }
              // adds each new chart found to the chart object
              createData(
                props.dataV2[index]?.data.data.name,
                props.dataV2[index]?.data.data.graphData[0]?.values,
                data?.color.hex,
                data?.scale.data,
                data?.style.data,
                data?.blockchain,
                data?.source,
                data?.resolution
              );
            });
          }
        }
      }
    }
  };

  // also used to help maintain sync
  const checkRemove = () => {
    let amntOfCharts = chartOptions.series.filter((x) => x["id"]).length;
    if (props.dataV2.length < amntOfCharts && props.remove != null) {
      removeData(
        props.remove.name,
        props.remove.blockchain,
        props.remove.source,
        props.remove.resolution
      );
    }
  };

  const resetCharts = () => {
    // console.log(resetChartsState);
    // if (resetChartsState) {
    const len = dataSource.length;
    yAxisSetup.splice(2, len - 2);
    dataSource.splice(2, len - 2);
    // resetChartsToggle;
    // }
  };

  // uses the update state to provide data for the update
  const updateAdditional = () => {
    if (props.updateChart != null) {
      var index = dataSource.findIndex(
        (data) =>
          data.id ===
          props.updateChart.name +
            props.updateChart.blockchain +
            props.updateChart.source +
            props.updateChart.resolution
      );
      var yAxisIndex = 0;
      if (index > -1) {
        yAxisIndex = yAxisSetup.findIndex(
          (obj) => obj.id === dataSource[index].yAxis
        );

        if (props.updateChart.key === "color") {
          dataSource[index].color = props.updateChart.update.hex;

          yAxisSetup[yAxisIndex].labels.style.color =
            props.updateChart.update.hex;
        }

        if (props.updateChart.key === "style") {
          dataSource[index].type = props.updateChart.update.data;
        }
        if (props.updateChart.key === "scale") {
          yAxisSetup[yAxisIndex].type = props.updateChart.update.data;
        }
      }

      setChartOptions({
        series: dataSource,
        yAxis: yAxisSetup,
      });
      props.setUpdateChart(null);
    }
  };

  // adds or removes the ema line from the options state
  const SetEMA = () => {
    // finds the ema line in the state
    let index = dataSource.map((object) => object.type).indexOf("ema");
    if (searchParams.get("ema") == "None" || searchParams.get("ema") == null) {
      if (index > 0) {
        dataSource.splice(index, 1);
        setChartOptions({
          series: dataSource,
        });
      }

      emaRef.current = 0;
    } else {
      if (index === -1) {
        emaRef.current = 1;
        dataSource.push({
          type: "ema",
          linkedTo: "first",
          showInLegend: true,
          params: {
            period: parseInt(searchParams.get("ema")),
          },
          color: "blue",
          dataGrouping: {
            approximation: "average",
            enabled: false,
            forced: true,
            units: [["day", [1]]],
          },
        });
        setChartOptions({
          series: dataSource,
        });
      } else {
        // EMA already exists and has been changed to a new value
        dataSource.splice(index, 1);
        dataSource.push({
          type: "ema",
          linkedTo: "first",
          showInLegend: true,
          params: {
            period: parseInt(searchParams.get("ema")),
          },
          color: "blue",
          dataGrouping: {
            approximation: "average",
            enabled: false,
            forced: true,
            units: [["day", [1]]],
          },
        });
        setChartOptions({
          series: dataSource,
        });
      }
    }
  };

  // adds or removes the sma line from the options state
  const SetSMA = () => {
    let index = dataSource.map((object) => object.type).indexOf("sma");
    if (searchParams.get("sma") == "None" || searchParams.get("sma") == null) {
      if (index > 0) {
        dataSource.splice(index, 1);
        setChartOptions({
          series: dataSource,
        });
      }

      smaRef.current = 0;
    } else {
      if (index === -1) {
        smaRef.current = 1;
        dataSource.push({
          type: "sma",
          linkedTo: "first",
          showInLegend: true,
          params: {
            period: parseInt(searchParams.get("sma")),
          },
          color: "red",
          dataGrouping: {
            approximation: "average",
            enabled: false,
            forced: true,
            units: [["day", [1]]],
          },
        });
        setChartOptions({
          series: dataSource,
        });
      } else {
        // SMA already exists and has been changed to a new value
        dataSource.splice(index, 1);
        dataSource.push({
          type: "sma",
          linkedTo: "first",
          showInLegend: true,
          params: {
            period: parseInt(searchParams.get("sma")),
          },
          color: "red",
          dataGrouping: {
            approximation: "average",
            enabled: false,
            forced: true,
            units: [["day", [1]]],
          },
        });
        setChartOptions({
          series: dataSource,
        });
      }
    }
  };

  const setScale = () => {
    // find every scale in the list then split and order by number, create loop that links each y axis to th eindex to update it
    var arrayTemp = [];
    for (const key of searchParams.keys()) {
      var objTemp = {};
      objTemp.key = key;
      objTemp.value = searchParams.get(key);
      arrayTemp.push(objTemp);
    }
    var i = 0;
    arrayTemp.forEach((value) => {
      if (value.key.includes("scale")) {
        if (value.value == "Log") {
          yAxisSetup[i].type = "logarithmic";
        } else {
          yAxisSetup[i].type = "linear";
        }

        i++;
      }
    });
    setChartOptions({
      series: dataSource,
      yAxis: yAxisSetup,
    });
    i = 0;
  };

  // grouping is unused for now but may be need later
  // is done in old way to may need to be updated if reenabled

  // const setGrouping = () => {
  //   if (searchParams.get("resolution") == "Daily") {
  //     dataSource.forEach((element) => {
  //       element.dataGrouping.enabled = true;
  //     });
  //   } else {
  //     dataSource.forEach((element) => {
  //       element.dataGrouping.enabled = false;
  //     });
  //   }
  // };
  //   const SetColour = () => {
  //     //   if (theme === "dark") {
  //     //     //   setBrush("#000000");
  //     //     setText("#32a852");
  //     //     // setInverseText("#000000");
  //     //     // setStroke(0.4);
  //     //   } else {
  //     setText("#000000");
  //     setBrush("#000000");
  //     // setInverseText("#ffffff");
  //     // setStroke(1.5);
  //     //   }
  //   };

  // updates the relevant line to bar or vice versa
  const updateSeries = () => {
    var arrayTemp = [];
    for (const key of searchParams.keys()) {
      var objTemp = {};
      objTemp.key = key;
      objTemp.value = searchParams.get(key);
      arrayTemp.push(objTemp);
    }
    var i = 0;
    arrayTemp.forEach((value) => {
      if (value.key.includes("type")) {
        if (value.value == "Bar") {
          dataSource[i].type = "column";
        } else {
          dataSource[i].type = "line";
        }

        i++;
      }
    });
    setChartOptions({
      series: dataSource,
      yAxis: yAxisSetup,
    });
    i = 0;
  };

  // Stores the data for each line on the chart as well as options for each line
  const [dataSource] = useState([
    {
      id: "first",
      name: results[0]?.data?.data?.name,
      data: results[0]?.data?.data?.graphData[0]?.values,
      color: "#FA9173",
      type: "line",
      yAxis: "1",
      // dataGrouping: {
      //   approximation: "average",
      //   enabled: false,
      //   forced: true,
      //   units: [["day", [1]]],
      // },
    },
    {
      id: "price",
      name: results[1]?.data?.data?.name,
      data: results[1]?.data?.data?.graphData[0]?.values,
      color: "#0E0C22",
      type: "line",
      yAxis: "2",
      // dataGrouping: {
      //   approximation: "average",
      //   enabled: false,
      //   forced: true,
      //   units: [["day", [1]]],
      // },
    },
  ]);
  // stores the yaxis and settings for each one
  const [yAxisSetup] = useState([
    {
      id: "1",
      opposite: false,
      labels: {
        style: {
          fontSize: "14px",
          color: "#FA9173",
        },
      },
      events: {},
    },
    {
      id: "2",
      opposite: true,
      labels: {
        style: {
          fontSize: "14px",
          color: "#0E0C22",
        },
      },
      events: {},
    },
  ]);

  // stores all the options for the entire chart inculding the data and y axis states.
  const [chartOptions, setChartOptions] = useState({
    exporting: {
      enabled: false,
      enableImages: true,
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: text,
        fontWeight: "bold",
      },
    },
    chart: {
      zoomType: "x",
      backgroundColor: "transparent",
      renderTo: this,
      style: {
        fontFamily: "Monda",
      },
      events: {
        load: function () {
          var height_image = 127;
          var width_image = 627.98;
          // var height_image = 48;
          // var width_image = 20;
          var textX = this.plotLeft + this.plotWidth * 0.5;
          var textY = this.plotTop + this.plotHeight * 0.5;
          var position_x = textX - width_image / 2;
          var position_y = textY - height_image / 2;
          // console.log(this.plotWidth);
          watermark = this.renderer
            .image(largeWatermark, position_x, position_y, 627.98, 127)
            .add();
        },
        redraw: function () {
          var plotWidth = this.plotWidth;
          var threshold = 800;

          if (plotWidth < threshold && watermark.href !== smallWatermark) {
            var height_image_sm = 100;
            var width_image_sm = 152;
            var textX_sm = this.plotLeft + this.plotWidth * 0.5;
            var textY_sm = this.plotTop + this.plotHeight * 0.5;
            var position_x_sm = textX_sm - width_image_sm / 2;
            var position_y_sm = textY_sm - height_image_sm / 2;
            watermark.attr({
              x: position_x_sm,
              y: position_y_sm,
            });
            watermark.attr({
              href: smallWatermark,
              width: width_image_sm,
              height: height_image_sm,
            });
          } else if (
            plotWidth >= threshold &&
            watermark.href !== largeWatermark
          ) {
            var height_image = 127;
            var width_image = 627.98;
            var textX = this.plotLeft + this.plotWidth * 0.5;
            var textY = this.plotTop + this.plotHeight * 0.5;
            var position_x = textX - width_image / 2;
            var position_y = textY - height_image / 2;
            watermark.attr({
              x: position_x,
              y: position_y,
            });
            watermark.attr({
              href: largeWatermark,
              width: width_image,
              height: height_image,
            });
          }
        },
      },
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      labels: {
        style: {
          fontSize: "13px",
        },
      },
      events: {
        setExtremes(e) {
          props.min(e.min);
          props.max(e.max);
          props.setMinZoom(e.min);
          props.setMaxZoom(e.max);
        },
      },
    },
    yAxis: yAxisSetup,
    series: dataSource,
    credits: {
      enabled: false,
    },
    navigator: {
      maskFill: "rgba(250, 145, 115, 0.35)",
    },
    rangeSelector: {
      enabled: false,
    },

    tooltip: {
      useHTML: true,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return (
            s +
            "<br/> <div>" +
            '<span style="color:' +
            point.color +
            '"> <b>' +
            point.series.name +
            ": " +
            "</b> </span>" +
            "<span>" +
            point.y.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            }) +
            "</span>"
          );
        }, "<b>" +
          moment.unix(this.x / 1000).format("DD/MM/YY, HH:mm:ss") +
          "</b>");
      },

      valueDecimals: 2,
      split: true,
      borderColor: "#FA9173",
      borderRadius: 8,
      borderWidth: 1,
      shared: true,
    },
    accessibility: {
      enabled: false,
    },
  });
  // updates the zoom of the chart based off the dates buttons
  useEffect(() => {
    if (chartRef != null) {
      chartRef.current.chart?.xAxis[0].setExtremes(
        props.minZoom,
        props.maxZoom
      );
    }
  }, [props.minZoom, props.maxZoom]);

  // ensures that the data is always updated and checks if window should be resized
  useEffect(() => {
    setChartOptions({
      series: dataSource,
    });
    window.dispatchEvent(new Event("resize"));
  }, []);

  // updates settings for the main chart when the url changes
  useEffect(() => {
    SetEMA();
    SetSMA();
    setScale();
    // setGrouping();
    updateSeries();
  }, [searchParams]);

  // trys to maintain sync
  useEffect(() => {
    checkForNewChart();
    checkRemove();
  }, [results]);

  useEffect(() => {
    resetCharts();
  }, [searchParams.get("name")]);

  // syncs the updates
  useEffect(() => {
    updateAdditional();
  }, [props.updateChart]);

  // checks the size of the chart when sidebar open/closed
  // useEffect(() => {
  //   window.dispatchEvent(new Event("resize"));
  //   console.log("cllaed");
  // }, [props.sidebar]);

  useEffect(() => {
    // resizeWatermark();
    // if (chartRef != null) {
    // window.dispatchEvent(new Event("resize"));
    // }
    // const handleResize = () => {
    if (chartRef.current) {
      chartRef.current.chart.reflow();
      // window.dispatchEvent(new Event("resize"));
    }
    // };

    // window.addEventListener("resize", handleResize);

    // // Call handleResize initially to ensure the chart is sized correctly on mount
    // handleResize();

    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, [width, props.sidebar]);

  useEffect(() => {
    var replace = {
      id: "first",
      name: results[0]?.data?.data?.name,
      data: results[0]?.data?.data?.graphData[0]?.values,
      color: "#FA9173",
      type: "line",
      yAxis: "1",
      // dataGrouping: {
      //   approximation: "average",
      //   enabled: false,
      //   forced: true,
      //   units: [["day", [1]]],
      // },
    };
    dataSource.splice(0, 1, replace);
  }, [
    searchParams.get("blockchain"),
    searchParams.get("resolution"),
    searchParams.get("name"),
    searchParams.get("source"),
  ]);

  useEffect(() => {
    var replace = {
      id: "price",
      name: results[1]?.data?.data?.name,
      data: results[1]?.data?.data?.graphData[0]?.values,
      color: "#737272",
      type: "line",
      yAxis: "2",
      // dataGrouping: {
      //   approximation: "average",
      //   enabled: false,
      //   forced: true,
      //   units: [["day", [1]]],
      // },
    };
    dataSource.splice(1, 1, replace);
  }, [searchParams.get("blockchain"), searchParams.get("resolution")]);

  return (
    <div className="grid h-[85vh] md:h-[75vh]">
      <HighchartsReact
        containerProps={{
          style: {
            height: "100%",
          },
        }}
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={chartOptions}
        ref={chartRef}
        oneToOne={true}
      />
    </div>
  );
}
export default Chart;
