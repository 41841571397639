import React, { useEffect, useState } from "react"; // Correctly import useState from React
import { useLocation, useNavigate } from "react-router-dom";
import NetworkGraph from "./networkGraph.js";
import { Card, CardTitle, CardContent } from "../../Ui/card.js";
import CardLoading from "../CardLoading.js";
import NetworkNotificationDialog from "./networkGraphDialog.js"; // Adjust the path as necessary
import UseNetworkQuery from "../API/UseNetworkQuery.js";
import { Button } from "../../Ui/button.js";

const DisplayNetworkGraph = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [minValue, setMinValue] = useState("0");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const location = useLocation();
  const navigate = useNavigate(); // Use the useNavigate hook
  useEffect(() => {
    if (localStorage.getItem("user") === null) {
      navigate("/Footprint");
    }
  }, []);
  const onSubmit = (newMinValue, newStartDate, newEndDate) => {
    setMinValue(newMinValue);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    // Optionally, trigger a refetch or update the query key here if needed
  };
  const address = new URLSearchParams(location.search).get("address");
  let transformedData = { nodes: [], links: [] };

  // Handle scenario when no address is provided
  if (!address) {
    return <div>Please enter an address to view the network graph.</div>;
  }

  // API request with dynamic address
  const {
    isLoading: networkLoading,
    data: networkData,
    isError: isNetworkError,
  } = UseNetworkQuery(minValue, startDate, endDate);

  // Transform data into the format expected by NetworkGraph
  const nodeOfFocus = address; // Or any other way you identify the node of focus

  if (networkData) {
    const nodesMap = new Map();
    let links = [];

    networkData.forEach((item) => {
      nodesMap.set(item.from_address, item.from_image);
      nodesMap.set(item.to_address, item.to_image);
      const valueInEth = item.value / 1e18;
      links.push({
        source: item.from_address,
        target: item.to_address,
        value: valueInEth,
        color:
          item.from_address.toLowerCase() === nodeOfFocus.toLowerCase()
            ? "rgba(255, 0, 0, 0.3)" // Red with 50% opacity
            : "rgba(0, 128, 0, 0.5)", // Green with 50% opacity
      });
    });

    const nodes = Array.from(nodesMap).map(([id, image]) => {
      return {
        id,
        image,
      };
    });

    transformedData = {
      nodes,
      links,
    };
  }

  return (
    <div className="w-full flex flex-col gap-4 font-roboto p-2 px-4">
      <div className="flex justify-center items-center h-screen">
        {/* Button to trigger dialog */}

        <Card className="w-full mx-auto flex flex-col h-full">
          {" "}
          {/* Ensure Card is a flex container */}
          <CardTitle className="px-2">
            {" "}
            <button
              className="my-2 py-2 px-4 text-white rounded bg-primary"
              onClick={() => setIsDialogOpen(true)}
            >
              Filter Network Graph
            </button>{" "}
          </CardTitle>
          <CardContent className="flex flex-col justify-center items-center h-full">
            {/* Make CardContent a flex container and allow it to grow */}
            {networkLoading ? (
              // Display the loading component for the entire card content section
              <CardLoading className="flex-grow" />
            ) : isNetworkError ? (
              <div className="h-96 font-monda text-5xl flex flex-col gap-4 text-center mt-6 text-red-400">
                error
                <p className="text-gray-700 text-3xl">
                  Can&apos;t retrieve values from this wallet address
                </p>
                <p className="text-gray-700 text-3xl">Refresh and try again</p>
                <Button
                  className="w-fit text-white mt-2"
                  onClick={() => navigate("/Footprint")}
                >
                  Return to Footprint
                </Button>
              </div>
            ) : (
              // Render the NetworkGraph component when data is loaded
              <NetworkGraph data={transformedData} />
            )}
          </CardContent>
        </Card>
      </div>

      {/* Dialog component */}
      <NetworkNotificationDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        onSubmit={onSubmit} // Pass the function here
      />
    </div>
  );
};

export default DisplayNetworkGraph;
